.nav {
	a {
		border: 0;
	}
	&.nav--main {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;

		.inbounz & {
			color: color("black");

			a {
				color: color("black");
			}
		}

		.outbounz & {
			color: color("white");

			a {
				color: color("white");
			}
		}
	}
	&.nav--fixed {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		animation: fade-in-down .35s ease-out;
		.inbounz & {
			box-shadow: 0 0 40px rgba(color("black"),.2);
		}
		.outbounz & {
			box-shadow: 0 0 40px rgba(color("corporate"),.2);
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			.inbounz & {
				background: color("corporate");
			}
			.outbounz & {
				background: color("black");
			}
		}
	}
	&.nav--absolute {
		position: absolute;
		top: 0;
		left: 0;
	}
}
@keyframes fade-in-down {
	from {opacity: 0; transform: translateY(-100%)}
	to {opacity: 1; transform: translateY(0)}
}
.nav-wrapper {
	position: relative;
	display: flex;
	width: 100%;
	max-width: $containerWidth;
	padding: 25px 50px;
	margin: 0 auto;
	justify-content: flex-start;
	align-items: center;
	line-height: 20px;
	.nav--main & {
		height: $navHeight;
	}
	.nav--sub & {
		min-height: $navHeight;
	}
	@media #{map-get($mediaqueries, "medium-down")} {
		padding: 10px 25px;
		.nav--main & {
			height: $navHeightMedium;
		}
		.nav--sub & {
			min-height: 50px;
			padding: 25px;
		}
	}
	@media #{map-get($mediaqueries, "small-down")} {
		display: block;
		.nav--sub & {
			text-align: center;
			.nav-logo {
				margin: 0;
			}
		}
	}
	@media (max-width: $navBreakpoint) {
		> * {
			z-index: 2;
		}
		&:before {
			content: "";
			position: absolute;
			top: -500px;
			left: 0;
			width: 100%;
			height: 0;
			border-radius: 100%;
			transition: .35s ease-out;
			transform-origin: center top;
			.inbounz & {
				background: color("corporate");
				box-shadow: 0 0 40px rgba(color("black"),.2);
			}
			.outbounz & {
				background: color("black");
				box-shadow: 0 0 40px rgba(color("corporate"),.2);
			}
		}
		.nav--opened &:before {
			top: 0;
			height: calc(100% + 100vh - $navHeight);
			border-radius: 0;
		}
	}
}
.nav-logo {
	position: relative;
	z-index: 2;
	display: inline-block;
	height: 36px;
	margin-right: 25px;
	svg, img {
		height: 100%;
		width: auto;
	}
	.logo-prim {
		fill: color("corporate");
	}
	.logo-sec {
		fill: color("black");
	}
	&.nav-logo--invert {
		.logo-prim {
			.inbounz & {
				fill: color("white");
			}
		}
		.logo-sec {
			.outbounz & {
				fill: color("white");
			}
		}
	}
	&:hover {
		.logo-prim {
			animation: logo-x .35s 1 normal ease-in-out;
		}
		.logo-prim--circle {
			animation: logo-y .35s 1 normal ease-in-out;
		}
	}
	&.nav-logo--big {
		margin: 25px;
		height: 75px;
	}
}
.nav-icon {
	position: relative;
	z-index: 2;
	display: block;
	height: 60px;
	margin-right: 25px;
	svg, img {
		height: 100%;
		width: auto;
	}
	.icon-bg {
		.inbounz & {
			fill: color("corporate");
		}
		.outbounz & {
			fill: color("black");
		}
	}
	.icon-prim {
		.inbounz & {
			fill: color("white");
		}
		.outbounz & {
			fill: color("corporate");
		}
	}
	.icon-sec {
		.inbounz & {
			fill: color("black");
		}
		.outbounz & {
			fill: color("white");
		}
	}
	&:last-child {
		margin-right: 0;
	}
	&.nav-icon--invert {
		.icon-bg {
			.inbounz & {
				fill: color("black");
			}
			.outbounz & {
				fill: color("corporate");
			}
		}
		.icon-prim {
			.inbounz & {
				fill: color("corporate");
			}
			.outbounz & {
				fill: color("white");
			}
		}
		.icon-sec {
			.inbounz & {
				fill: color("white");
			}
			.outbounz & {
				fill: color("black");
			}
		}
	}
	&:hover {
		.icon-prim {
			animation: logo-x .35s 1 normal ease-in-out;
		}
		.icon-prim--circle {
			animation: logo-y .35s 1 normal ease-in-out;
		}
	}
}
@keyframes logo-x {
	0% {
		transform: translateX(0);
		opacity: 1;
	}
	50% {
		transform: translateX(-50px);
		opacity: 0;
	}
	50.1% {
		transform: translateX(50px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes logo-y {
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	50% {
		transform: translateY(-50px);
		opacity: 0;
	}
	50.1% {
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
.nav-items {
	list-style-type: none;
	margin: 0;
	padding: 0;
	@media (max-width: $navBreakpoint) {
		position: absolute;
		top: 50px;
		left: 0;
		width: 100%;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		transition: opacity .35s ease-in-out;
		.nav--opened & {
			height: calc(100vh - $navHeight);
			visibility: visible;
			opacity: 1;
			pointer-events: auto;
		}
		.nav--sub & {
			position: static;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			text-align: center;
			visibility: visible;
			opacity: 1;
			pointer-events: auto;
		}
	}
	@media #{map-get($mediaqueries, "small-down")} {
		.nav--sub & {
			display: block;
		}
	}
	.nav-item {
		position: relative;
		display: inline-block;
		margin: 0;
		@media (max-width: $navBreakpoint) {
			margin: 10px 0;
		}
		> a {
			position: relative;
			@extend .subtitle;
			display: inline-block;
			padding: 10px 20px;
			transition: color .2s ease-in;
		    overflow: hidden;
		}
		& > a:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 40px;
			height: 40px;
			border-radius: 20px;
			z-index: -1;
			transform: translateY(-100%);
			transition: transform .1s .2s ease-out, width .2s ease-in;
			.inbounz & {
				background: color("black");
			}
			.outbounz & {
				background: color("corporate");
			}
			.nav--sub & {
				.inbounz & {
					background: color("corporate");
				}
				.outbounz & {
					background: color("black");
				}
			}
		}
		&:hover > a {
			transition: color .2s .1s ease-out;
			.inbounz & {
				color: color("corporate");
			}
			.outbounz & {
				color: color("black");
			}
			.nav--sub & {
				.inbounz & {
					color: color("black");
				}
				.outbounz & {
					color: color("corporate");
				}
			}
			&:before {
				transition: transform .1s ease-in, width .1s .2s ease-out;
				width: 100%;
				transform: translateY(0);
			}
		}
		@media (max-width: $navBreakpoint) {
			text-align: center;
			& > a.active {
				.inbounz & {
					color: color("corporate");
				}
				.outbounz & {
					color: color("black");
				}
			}
			& > a.active:before {
				transition: transform .1s ease-in, width .1s .2s ease-out;
				width: 100%;
				transform: translateY(0);
			}
		}
	}
	.nav-languages-wrapper {
		display: block;
		position: absolute;
		top: 30px;
		right: 50px;
		.nav--fixed:not(.nav--nocta) & {
			display: none;
		}
		@media #{map-get($mediaqueries, "medium-down")} {
			top: 10px;
			right: 25px;
		}
		@media (max-width: $navBreakpoint) {
			display: none;
			.nav--opened & {
				display: block;
				position: static;
				margin: 10px 0 25px;
			}
		}
	}
	.nav-cta {
		display: none;
		position: absolute;
		top: 30px;
		right: 50px;
		.nav--fixed:not(.nav--nocta) & {
			display: block;
		}
		> a {
			.inbounz & {
				color: color("corporate");
			}
			.outbounz & {
				color: color("black");
			}
			margin: 0;
		}
		@media #{map-get($mediaqueries, "medium-down")} {
			top: 10px;
			right: 25px;
		}
		@media (max-width: $navBreakpoint) {
			display: block;
			position: static;
			margin: 10px 0 25px;
		}
	}
}
.nav-subitems {
	visibility: hidden;
	transform: translateY(-25px);
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 40px;
	left: -5px;
	z-index: 9;
	list-style-type: none;
	margin: 0;
	padding: 22px 25px 10px;
	width: 275px;
	transition: .25s ease-in;
	.inbounz & {
		color: color("white");
	}
	.outbounz & {
		color: color("black");
	}
	&:before {
		content: "";
		position: absolute;
		top: 10px;
		left: 0;
		width: 100%;
		height: calc(100% - 10px);
		z-index: -1;
		border-radius: 20px;
		box-shadow: 0 3px 3px rgba(0,0,0,.5);
		.inbounz & {
			background: color("black");
		}
		.outbounz & {
			background: color("corporate");
		}
	}
	@media (min-width: #{$navBreakpoint + 1px}) {
		li:hover > & {
			visibility: visible;
			transform: translateX(0);
			opacity: 1;
			pointer-events: auto;
			transition: .25s ease-out;
		}
	}
	@media (max-width: $navBreakpoint) {
		position: relative;
		top: 0;
		display: none;
		overflow: hidden;
		transform: none;
		visibility: visible;
	    pointer-events: auto;
		transition: opacity .35s ease-in-out;
		&:before {
			box-shadow: inset 0 -3px 6px rgba(255,255,255,.5);
			top: 0;
			height: 100%;
			.inbounz & {
				background: color("corporate");
			}
			.outbounz & {
				background: color("black");
			}
		}
	    .active + & {
	    	opacity: 1;
	    }
	}
	.nav-subitem {
		display: block;
		transition: .25s ease-out;
		> a {
			padding: 10px 0;
			font-weight: normal;
			.inbounz & {
				color: color("corporate");
			}
			.outbounz & {
				color: color("black");
			}
			@media (max-width: $navBreakpoint) {
				.inbounz & {
					color: color("black");
				}
				.outbounz & {
					color: color("corporate");
				}
			}
		}
		&:hover {
			transform: translateX(5px);
		}
	}
}
.nav-languages {
	list-style-type: none;
	margin: 0;
	padding: 0;
	text-align: center;
	li {
			position: relative;
			display: inline-block;
			margin: 0;
		> a {
			@extend .subtitle;
			display: inline-block;
			height: 40px;
			width: 40px;
			padding: 10px 0;
			transition: .2s ease-out;
		}
		&.active > a {
			.inbounz & {
				color: color("black");
			}
			.outbounz & {
				color: color("black");
			}
		}
		&:hover > a {
			.inbounz & {
				color: color("corporate");
			}
			.outbounz & {
				color: color("black");
			}
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 20px;
			z-index: -1;
			transform: scale(0);
			transition: transform .2s ease-out, background .2s ease-out;
			.nav--sub & {
				.inbounz & {
					background: color("corporate");
				}
				.outbounz & {
					background: color("black");
				}
			}
		}
		&.active:before {
			transform: scale(1);
			.inbounz & {
				background: color("white");
			}
			.outbounz & {
				background: color("corporate");
			}
		}
		&:hover:before {
			transform: scale(1);
			.inbounz & {
				background: color("black");
			}
			.outbounz & {
				background: color("white");
			}
		}
	}
	&:hover li:after {
		opacity: 1;
	}
}
.nav-burger {
	display: none;
	position: absolute;
	top: 5px;
	right: 15px;
	height: 50px;
	width: 50px;
	cursor: pointer;
	overflow: hidden;
	@media (max-width: $navBreakpoint) {
		display: block;
	}
	span {
		position: absolute;
		right: 10px;
		width: 20px;
		height: 4px;
		border-radius: 2px;
		transition: .2s ease-in-out;
		.inbounz & {
			background: color("black");
		}
		.outbounz & {
			background: color("white");
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			width: 4px;
			height: 4px;
			border-radius: 2px;
			transition: .2s ease-in-out;
			.inbounz & {
				background: color("black");
			}
			.outbounz & {
				background: color("white");
			}
		}
		&:nth-child(1) {
			top: 13px;
			right: 10px;
			&:before {
				left: -8px;
			}
		}
		&:nth-child(2) {
			top: 23px;
			right: 20px;
			&:before {
				right: -8px;
			}
		}
		&:nth-child(3) {
			top: 33px;
			right: 10px;
			&:before {
				left: -8px;
			}
		}
	}
	&:hover {
		span:nth-child(1) {
			right: 20px;
			&:before {
				left: calc(100% + 4px);
			}
		}
		span:nth-child(2) {
			right: 10px;
			&:before {
				right: calc(100% + 4px);
			}
		}
		span:nth-child(3) {
			right: 20px;
			&:before {
				left: calc(100% + 4px);
			}
		}
	}
	.nav--opened & {
		span:nth-child(1) {
			top: 23px;
			right: 11px;
			width: 28px;
			transform: rotate(45deg);
			transform-origin: center center;
			&:before {
				left: 0;
			}
		}
		span:nth-child(2) {
			width: 4px;
			right: calc(50% - 2px);
		}
		span:nth-child(3) {
			top: 23px;
			right: 11px;
			width: 28px;
			transform: rotate(-45deg);
			transform-origin: center center;
			&:before {
				left: 0;
			}
		}
	}
}
.nav-info {
	position: absolute;
	right: 50px;
	line-height: 50px;
	@media #{map-get($mediaqueries, "medium-down")} {
		right: 25px;
	}
	@media #{map-get($mediaqueries, "small-down")} {
		position: static;
	}
}