///* ========================================================================
//   VARIABLES
//   ======================================================================== */

$navBreakpoint: 950px;
$navHeight: 100px;
$navHeightMedium: 60px;
$containerWidth: 1200px;

// For the colors utility we need to store the color variables in a single list
// Example / color: map-get($colors, prim)

$colors: (
	"corporate": #FFE800,
	"grey": #eeeeee,	
	"white": #ffffff,
	"black": #000000
);

// Media Query Ranges
// Example / @media map-get($mediaqueries, medium-up)

$breakpoints: (
	xsmall: 420px,
	small: 680px,
	medium: 1020px,
	large: 1440px,
	xlarge: 1919px
);

$mediaqueries: $breakpoints;

@each $key, $value in $breakpoints {

	$prev-value: map-get-prev($breakpoints, $key, 0px);
	$next-value: map-get-next($breakpoints, $key, 0px);
	$mediaqueries: map-merge($mediaqueries, ("#{$key}-up": "(min-width: #{$value + 1px})"));
	$mediaqueries: map-merge($mediaqueries, ("#{$key}-down": "(max-width: #{$value})"));
	$mediaqueries: map-merge($mediaqueries, ("#{$key}-only": "(min-width: #{$prev-value}) and (max-width: #{$value})"));
	$mediaqueries: map-merge($mediaqueries, ("#{$key}-portrait-up": "(orientation: portrait) and (min-width: #{$value + 1px})"));
	$mediaqueries: map-merge($mediaqueries, ("#{$key}-portrait-down": "(orientation: portrait) and (max-width: #{$value})"));
	$mediaqueries: map-merge($mediaqueries, ("#{$key}-portrait-only": "(orientation: portrait) and (min-width: #{$prev-value}) and (max-width: #{$value})"));
	$mediaqueries: map-merge($mediaqueries, ("#{$key}-landscape-up": "(orientation: landscape) and (min-width: #{$value + 1px})"));
	$mediaqueries: map-merge($mediaqueries, ("#{$key}-landscape-down": "(orientation: landscape) and (max-width: #{$value})"));
	$mediaqueries: map-merge($mediaqueries, ("#{$key}-landscape-only": "(orientation: landscape) and (min-width: #{$prev-value}) and (max-width: #{$value})"));

}