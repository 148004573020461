.siteswapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 315px;
	height: 100%;
	z-index: 11;
	transform: translateX(-100%) translateX(15px);
	transition: .35s ease-out;
	.siteswapper-logo {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: $navHeight;
		pointer-events: none;
		border: 0;
		@media #{map-get($mediaqueries, "medium-down")} {
			height: $navHeightMedium;
		}
		@extend .nav-wrapper;
		svg {
			@extend .nav-logo;
			@extend .nav-logo--invert;
			width: auto;
			.logo-prim {
				.outbounz & {
					fill: color("white");
				}
				.inbounz & {
					fill: color("corporate");
				}
			}
			.logo-sec {
				.outbounz & {
					fill: color("black");
				}
				.inbounz & {
					fill: color("white");
				}
			}
		}
	}
	.siteswapper-wrapper {
		height: 100%;
	}
	> .siteswapper-wrapper {
		width: calc(100% - 15px);
		box-shadow: 0 0 5px rgba(0, 0, 0, .5);
		.container {
			transition: .35s ease-out;
		}
	}
	.siteswapper-icon {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(-15px, -50%);
		cursor: pointer;
		transition: .35s ease-out;
		box-shadow: 0 0 5px rgba(0, 0, 0, .5);
		border-radius: 50%;
		.inbounz & {
			box-shadow: 0 0 5px rgba(color("corporate"), .75);
		}
		svg {
			.icon-bg {
				.inbounz & {
					fill: color("black");
				}
				.outbounz & {
					fill: color("corporate");
				}
			}
			.icon-prim {
				.inbounz & {
					fill: color("corporate");
				}
				.outbounz & {
					fill: color("white");
				}
			}
			.icon-sec {
				.inbounz & {
					fill: color("white");
				}
				.outbounz & {
					fill: color("black");
				}
			}
		}
	}
	&.siteswapper--show {
		transform: translateX(-100%) translateX(15px);
		.siteswapper-icon {
			transform: translate(50%, -50%);
		}
	}
	&.siteswapper--opened,
	&:hover {
		transform: translateX(0);
		.siteswapper-logo {
			pointer-events: all;
		}
		.siteswapper-icon {
			transform: translate(50%, -50%) translateX(-15px);
		}
	}
	&.siteswapper--extended {
		transform: translateX(0);
		width: calc(100% + 15px);
		pointer-events: none;
		.siteswapper-icon {
			transform: translate(100%, -50%);
		}
		.container {
			opacity: 0;
		}
		.siteswapper-logo {
			@media (min-width: $containerWidth) {
				left: calc((100vw - $containerWidth) / 2);
			}
		}
	}
}