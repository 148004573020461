.blog {
    @include container(800px);
}
.blog-overview {
    @include container(none, false);
    padding-left: 0;
    padding-right: 0;
}
.blog-teasers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.blog-teaser {
    position: relative;
    width: 300px;
    max-width: calc(100% - 30px);
    margin: 15px;
    .button {
        margin-bottom: 0;
        margin-top: 15px;
    }
    &.blog-teaser--withbg {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: color("white");
        }
        .blog-teaser-text {
            padding: 15px;
        }
    }
}
.blog-teaser-text {
    padding-top: 15px;
}