html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
}

[type="button"] {
  -webkit-appearance: button;
}

[type="reset"] {
  -webkit-appearance: button;
}

[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html.fp-enabled, .fp-enabled body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.fp-section {
  box-sizing: border-box;
  position: relative;
}

.fp-slide {
  float: left;
}

.fp-slide, .fp-slidesContainer {
  height: 100%;
  display: block;
}

.fp-slides {
  z-index: 1;
  height: 100%;
  transition: all .3s ease-out;
  position: relative;
  overflow: hidden;
}

.fp-section.fp-table, .fp-slide.fp-table {
  table-layout: fixed;
  width: 100%;
  display: table;
}

.fp-tableCell {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  display: table-cell;
}

.fp-slidesContainer {
  float: left;
  position: relative;
}

.fp-controlArrow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  z-index: 4;
  cursor: pointer;
  border-style: solid;
  width: 0;
  height: 0;
  margin-top: -38px;
  position: absolute;
  top: 50%;
  transform: translate3d(0, 0, 0);
}

.fp-controlArrow.fp-prev {
  border-width: 38.5px 34px 38.5px 0;
  border-color: rgba(0, 0, 0, 0) #fff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  width: 0;
  left: 15px;
}

.fp-controlArrow.fp-next {
  border-width: 38.5px 0 38.5px 34px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff;
  right: 15px;
}

.fp-scrollable {
  position: relative;
  overflow: hidden;
}

.fp-scroller {
  overflow: hidden;
}

.iScrollIndicator {
  border: 0 !important;
}

.fp-notransition {
  transition: none !important;
}

#fp-nav {
  z-index: 100;
  opacity: 1;
  transform: translateY(-50%);
  position: fixed;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
}

#fp-nav.fp-right {
  right: 17px;
}

#fp-nav.fp-left {
  left: 17px;
}

.fp-slidesNav {
  z-index: 4;
  opacity: 1;
  position: absolute;
  right: 0;
  transform: translate3d(0, 0, 0);
  margin: 0 auto !important;
  left: 0 !important;
}

.fp-slidesNav.fp-bottom {
  bottom: 17px;
}

.fp-slidesNav.fp-top {
  top: 17px;
}

#fp-nav ul, .fp-slidesNav ul {
  margin: 0;
  padding: 0;
}

#fp-nav ul li, .fp-slidesNav ul li {
  width: 14px;
  height: 13px;
  margin: 7px;
  display: block;
  position: relative;
}

.fp-slidesNav ul li {
  display: inline-block;
}

#fp-nav ul li a, .fp-slidesNav ul li a {
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: block;
  position: relative;
}

#fp-nav ul li a.active span, .fp-slidesNav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li:hover a.active span {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  margin: -6px 0 0 -6px;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
  z-index: 1;
  -o-transition: all .1s ease-in-out;
  background: #333;
  border: 0;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
}

#fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
}

#fp-nav ul li .fp-tooltip {
  color: #fff;
  white-space: nowrap;
  opacity: 0;
  cursor: pointer;
  width: 0;
  max-width: 220px;
  font-family: arial, helvetica, sans-serif;
  font-size: 14px;
  display: block;
  position: absolute;
  top: -2px;
  overflow: hidden;
}

#fp-nav ul li:hover .fp-tooltip, #fp-nav.fp-show-active a.active + .fp-tooltip {
  opacity: 1;
  width: auto;
  transition: opacity .2s ease-in;
}

#fp-nav ul li .fp-tooltip.fp-right {
  right: 20px;
}

#fp-nav ul li .fp-tooltip.fp-left {
  left: 20px;
}

.fp-auto-height.fp-section, .fp-auto-height .fp-slide, .fp-auto-height .fp-tableCell, .fp-responsive .fp-auto-height-responsive.fp-section, .fp-responsive .fp-auto-height-responsive .fp-slide, .fp-responsive .fp-auto-height-responsive .fp-tableCell {
  height: auto !important;
}

.fp-sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid rgba(0, 0, 0, 0);
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  font-family: sofia-pro-soft, serif;
  font-size: 1rem;
  line-height: 1.4;
}

body {
  scroll-behavior: smooth;
  margin: 0;
  position: relative;
  overflow-x: hidden;
}

main {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

a {
  color: inherit;
  border-bottom: 2px solid #ffe800;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  font-weight: 700;
  text-decoration: none;
  transition: border .2s ease-out;
}

a:hover {
  border-bottom-width: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

p {
  margin: .75rem 0;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

ul, ol, li > ol, li > ul {
  margin-bottom: .75rem;
}

:not(li) > ul:last-child, :not(li) > ol:last-child {
  margin-bottom: 0;
}

li {
  margin: 0 0 .75rem;
}

hr {
  background-image: url("data:image/svg+xml,%3Csvg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 20 4\"%3E%3Ccircle fill=\"black\" cx=\"2\" cy=\"2\" r=\"2\"/%3E%3Crect fill=\"black\" x=\"6.33\" width=\"11.33\" height=\"4\" rx=\"2\"/%3E%3C/svg%3E");
  background-position: 0;
  background-repeat: repeat-x;
  background-size: contain;
  border: none;
  outline: none;
  width: 100%;
  height: 4px;
  margin: 0;
  display: block;
}

.inbounz .container-wrapper--black hr, .outbounz .container-wrapper--color hr {
  background-image: url("data:image/svg+xml,%3Csvg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 20 4\"%3E%3Ccircle fill=\"#ffffff\" cx=\"2\" cy=\"2\" r=\"2\"/%3E%3Crect fill=\"white\" x=\"6.33\" width=\"11.33\" height=\"4\" rx=\"2\"/%3E%3C/svg%3E");
}

img, svg {
  width: 100%;
  max-width: 100%;
}

img.portrait, svg.portrait {
  background: rgba(0, 0, 0, .07);
  border: 10px solid #ffe800;
  border-radius: 50%;
  max-width: 125px;
  margin-bottom: 1rem;
  display: inline-block;
}

.inbounz .container-wrapper--color img.portrait, .outbounz .container-wrapper--black img.portrait, .inbounz .container-wrapper--color svg.portrait, .outbounz .container-wrapper--black svg.portrait {
  background: rgba(255, 255, 255, .5);
  border-color: #fff;
}

figure {
  margin: 1.5rem 0;
  padding: 0;
  position: relative;
}

figure.can-fullscreen {
  cursor: pointer;
}

figure.can-fullscreen.is-fullscreen {
  z-index: 999;
  background: rgba(0, 0, 0, .8);
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

figure.can-fullscreen.is-fullscreen .figure-close {
  display: block;
}

figure.can-fullscreen.is-fullscreen img {
  object-fit: contain;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100vh;
}

figure .figure-close {
  text-align: center;
  cursor: pointer;
  background: rgba(255, 255, 255, .5);
  width: 40px;
  padding: 5px;
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
  line-height: 30px;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

figcaption {
  background: rgba(255, 255, 255, .5);
  padding: 5px;
  font-style: italic;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

*, :before, :after {
  box-sizing: inherit;
}

.text-icon {
  border-radius: 50%;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-right: .125em;
  padding: .25em;
  display: -ms-inline-flexbox;
  display: inline-flex;
  transform: translateY(.125em);
}

.inbounz .text-icon {
  color: #000;
  background: #ffe800;
}

.outbounz .text-icon {
  color: #fff;
  background: #000;
}

.text-icon svg {
  height: 1em;
}

.container-wrapper {
  min-height: calc(100vh - 100px);
  position: relative;
}

@media (max-width: 1020px) {
  .container-wrapper {
    min-height: calc(100vh - 50px);
  }
}

.container-wrapper.container-wrapper--slim {
  min-height: auto;
}

.container-wrapper.container-wrapper--clipped {
  overflow: hidden;
}

.container-wrapper.container-wrapper--fullheight {
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
}

.container-wrapper--color, .container-wrapper--black, .container-wrapper--grey {
  position: relative;
}

.container {
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 150px 50px 50px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media (max-width: 1020px) {
  .container {
    padding: 85px 25px 25px;
  }
}

@media (max-width: 680px) {
  .container {
    min-height: 40vh;
  }

  .container-wrapper--center .container {
    min-height: 100vh;
  }
}

.outbounz .container-wrapper--black .container, .inbounz .container-wrapper--color .container {
  color: #000;
}

.outbounz .container-wrapper--black .container a:not(.button), .inbounz .container-wrapper--color .container a:not(.button) {
  color: #000;
  border-color: #fff;
}

.outbounz .container-wrapper--black .container h4, .inbounz .container-wrapper--color .container h4 {
  color: #000;
}

.outbounz .container-wrapper--color .container, .inbounz .container-wrapper--black .container, .outbounz .container-wrapper--color .container a:not(.button), .inbounz .container-wrapper--black .container a:not(.button) {
  color: #fff;
}

.container-wrapper--slim .container {
  -ms-flex-pack: center;
  justify-content: center;
  min-height: auto;
}

.container.container--slim {
  padding: 50px;
}

@media (max-width: 1020px) {
  .container.container--slim {
    padding: 25px;
  }
}

.container.container--fullwidth {
  max-width: none;
}

.container-bgcolor {
  z-index: -2;
  pointer-events: none;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.outbounz .container-wrapper--black .container-bgcolor, .inbounz .container-wrapper--color .container-bgcolor {
  background-color: #ffe800;
}

.outbounz .container-wrapper--color .container-bgcolor, .inbounz .container-wrapper--black .container-bgcolor {
  background-color: #000;
}

.container-wrapper--grey .container-bgcolor {
  background-color: #eee;
}

.container-bgcolor-img {
  mix-blend-mode: soft-light;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.container-bg {
  z-index: 0;
  pointer-events: none;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
  height: calc(100% - 100px);
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 100px;
  left: 50%;
  overflow: hidden;
}

.container-bg > img {
  object-fit: contain;
  width: 125%;
  max-width: none;
  height: 100%;
  display: block;
}

@media (max-width: 1020px) {
  .container-bg {
    height: calc(100% - 60px);
    top: 60px;
  }
}

@media (max-width: 680px) {
  .container-bg {
    width: 100%;
    height: auto;
    padding: 25px 0 50px;
    position: static;
  }

  .container-bg > img {
    width: calc(100% - 40px);
    position: relative;
    left: 20px;
  }
}

.container-bg.container-bg--fullpage {
  z-index: -1;
  filter: grayscale(50%) brightness(80%);
  mix-blend-mode: luminosity;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.container-bg.container-bg--fullpage > div {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

@media (max-width: 1020px) {
  .container-bg.container-bg--fullpage {
    height: 100%;
    top: 0;
  }
}

@media (max-width: 680px) {
  .container-bg.container-bg--fullpage {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.container-tags {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.container-tags > .button, .container-tags > .button:hover {
  color: #000;
  margin-top: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.container-video {
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
  height: calc(100% - 100px);
  padding-left: 50px;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 100px;
  left: 50%;
  overflow: hidden;
}

@media (max-width: 1020px) {
  .container-video {
    height: calc(100% - 50px);
    padding-left: 25px;
    top: 50px;
  }
}

@media (max-width: 680px) {
  .container-video {
    width: 100%;
    height: auto;
    padding-left: 0;
    position: static;
  }
}

.container-heading {
  width: 50%;
}

.container-wrapper--center .container-heading {
  text-align: center;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
}

.container-heading.container-heading--fullpage {
  width: 100%;
  max-width: 550px;
}

@media (max-width: 680px) {
  .container-heading {
    width: 100%;
  }
}

.container-imgtext {
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 875px;
  display: -ms-flexbox;
  display: flex;
}

.container-imgtext > :first-child {
  width: 25%;
  padding-left: 0;
  padding-right: 25px;
}

.container-imgtext > :last-child {
  width: 75%;
}

.container-imgtext.container-imgtext--inverted {
  text-align: right;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.container-imgtext.container-imgtext--inverted > :first-child {
  -ms-flex-order: 2;
  order: 2;
  padding-left: 25px;
  padding-right: 0;
}

@media (max-width: 680px) {
  .container-imgtext > :first-child {
    width: 35%;
  }

  .container-imgtext > :last-child {
    width: 65%;
  }
}

@media (max-width: 420px) {
  .container-imgtext {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .container-imgtext > :first-child {
    text-align: center;
    width: 100%;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .container-imgtext > :first-child img {
    max-width: 175px;
    display: inline-block;
  }

  .container-imgtext > :last-child {
    text-align: center;
    width: 100%;
  }

  .container-imgtext.container-imgtext--inverted {
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .container-imgtext.container-imgtext--inverted > :first-child {
    -ms-flex-order: 0;
    order: 0;
  }
}

.container-img {
  width: auto;
  max-height: 50vh;
}

@media (max-width: 1020px) {
  .container-img {
    margin-bottom: 50px;
  }
}

.container-icon {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.container-footer {
  width: 100%;
  padding: 25px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.outbounz .container-wrapper--black .container-footer, .inbounz .container-wrapper--color .container-footer {
  color: #000;
}

.outbounz .container-wrapper--color .container-footer, .inbounz .container-wrapper--black .container-footer {
  color: #fff;
}

.container-wrapper--center .container-footer {
  text-align: center;
}

@media (max-width: 680px) {
  .container-footer + .container {
    padding-bottom: 50px;
  }
}

.container-badge {
  z-index: 5;
  border: 0;
  width: 220px;
  max-width: calc(100% - 100px);
  position: absolute;
  bottom: 50px;
  right: 50px;
}

@media (max-width: 1020px) {
  .container-badge {
    bottom: 25px;
    right: 25px;
  }
}

.container-columns {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: -25px;
  display: -ms-flexbox;
  display: flex;
}

.container-columns > * {
  text-align: center;
  width: 100%;
  max-width: 300px;
  padding: 25px;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: .875rem;
  margin-bottom: .875rem;
}

h1, .h1 {
  margin: 0 0 .75rem;
  font-size: 2.375rem;
  font-weight: bold;
  line-height: 1.2;
}

@media (min-width: 1021px) {
  h1, .h1 {
    font-size: calc(2.375rem + 2.5vw - 25.5px);
  }
}

@media (min-width: 1920px) {
  h1, .h1 {
    font-size: 3.75rem;
  }
}

@media (max-width: 680px) {
  h1, .h1 {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}

h2, .h2 {
  margin: 0 0 .75rem;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.2;
}

@media (min-width: 1021px) {
  h2, .h2 {
    font-size: calc(1.875rem + 1.5vw - 15.3px);
  }
}

@media (min-width: 1920px) {
  h2, .h2 {
    font-size: 2.75rem;
  }
}

@media (max-width: 680px) {
  h2, .h2 {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}

h3, .h3 {
  margin: 0 0 .75rem;
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.2;
}

@media (min-width: 1021px) {
  h3, .h3 {
    font-size: calc(1.75rem + 1vw - 10.2px);
  }
}

@media (min-width: 1920px) {
  h3, .h3 {
    font-size: 2.125rem;
  }
}

@media (max-width: 680px) {
  h3, .h3 {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}

h4, .h4 {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 #000;
  margin: 0;
  font-size: .875rem;
  font-weight: normal;
}

h5, .h5 {
  margin: 0 0 .75rem;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
}

.subtitle, .nav-languages li > a, .nav-items .nav-item > a {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: .875rem;
  font-weight: normal;
}

.intro {
  margin: 0 0 .75rem;
  font-size: 1.125rem;
}

@media (min-width: 1021px) {
  .intro {
    font-size: calc(1.125rem + .25vw - 2.55px);
  }
}

@media (min-width: 1920px) {
  .intro {
    font-size: 1.25rem;
  }
}

@media (max-width: 680px) {
  .intro {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}

.intro-link {
  white-space: nowrap;
  font-size: 1rem;
}

.intro-link i {
  margin: 0 15px;
}

@media (max-width: 680px) {
  .intro-link i {
    margin-left: 0;
  }

  .intro-link {
    display: block;
  }
}

@keyframes fade-from-top {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-from-left {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.button {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #000;
  vertical-align: middle;
  border: 0;
  margin: 1.5rem 0;
  padding: 10px 35px;
  font-size: .875rem;
  font-weight: normal;
  transition: all .2s ease-out .1s;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.inbounz .button {
  color: #000;
}

.outbounz .button {
  color: #ffe800;
}

.button:before, .button:after {
  content: "";
  z-index: -1;
  border-radius: 20px;
  width: 40px;
  height: 100%;
  transition: all .2s ease-out;
  position: absolute;
}

.inbounz .button:before, .inbounz .button:after {
  background: #ffe800;
}

.outbounz .button:before, .outbounz .button:after {
  background: #000;
}

.button:after {
  transition: all .1s ease-out;
  top: 0;
  right: 0;
  transform: translateY(-100%);
}

.button:before {
  width: 100%;
  transition: all .2s ease-out .1s;
  bottom: 0;
  left: 0;
}

.button:hover {
  padding: 10px 60px 10px 10px;
  transition: all .2s ease-out;
}

.button:hover:after {
  transition: all .1s ease-in .1s;
  transform: translateY(0);
}

.button:hover:before {
  width: calc(100% - 50px);
  transition: all .2s ease-out;
}

.inbounz .button.button--color {
  color: #ffe800;
}

.inbounz .button.button--color:before, .inbounz .button.button--color:after {
  background: #000;
}

.outbounz .button.button--color {
  color: #000;
}

.outbounz .button.button--color:before, .outbounz .button.button--color:after {
  background: #ffe800;
}

.button.button--highlight {
  box-shadow: 0 0 6px rgba(0, 0, 0, .35);
}

.button.button--highlight:hover {
  box-shadow: none;
}

.button.button--disabled {
  opacity: .5;
  pointer-events: none;
  cursor: default;
}

.nav a {
  border: 0;
}

.nav.nav--main {
  z-index: 1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.inbounz .nav.nav--main, .inbounz .nav.nav--main a {
  color: #000;
}

.outbounz .nav.nav--main, .outbounz .nav.nav--main a {
  color: #fff;
}

.nav.nav--fixed {
  z-index: 10;
  animation: .35s ease-out fade-in-down;
  position: fixed;
  top: 0;
  left: 0;
}

.inbounz .nav.nav--fixed {
  box-shadow: 0 0 40px rgba(0, 0, 0, .2);
}

.outbounz .nav.nav--fixed {
  box-shadow: 0 0 40px rgba(255, 232, 0, .2);
}

.nav.nav--fixed:before {
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.inbounz .nav.nav--fixed:before {
  background: #ffe800;
}

.outbounz .nav.nav--fixed:before {
  background: #000;
}

.nav.nav--absolute {
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-wrapper, .siteswapper .siteswapper-logo {
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 25px 50px;
  line-height: 20px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.nav--main .nav-wrapper, .nav--main .siteswapper .siteswapper-logo, .siteswapper .nav--main .siteswapper-logo {
  height: 100px;
}

.nav--sub .nav-wrapper, .nav--sub .siteswapper .siteswapper-logo, .siteswapper .nav--sub .siteswapper-logo {
  min-height: 100px;
}

@media (max-width: 1020px) {
  .nav-wrapper, .siteswapper .siteswapper-logo {
    padding: 10px 25px;
  }

  .nav--main .nav-wrapper, .nav--main .siteswapper .siteswapper-logo, .siteswapper .nav--main .siteswapper-logo {
    height: 60px;
  }

  .nav--sub .nav-wrapper, .nav--sub .siteswapper .siteswapper-logo, .siteswapper .nav--sub .siteswapper-logo {
    min-height: 50px;
    padding: 25px;
  }
}

@media (max-width: 680px) {
  .nav-wrapper, .siteswapper .siteswapper-logo {
    display: block;
  }

  .nav--sub .nav-wrapper, .nav--sub .siteswapper .siteswapper-logo, .siteswapper .nav--sub .siteswapper-logo {
    text-align: center;
  }

  .nav--sub .nav-wrapper .nav-logo, .siteswapper .siteswapper-logo .nav--sub .nav-wrapper svg, .nav--sub .siteswapper .siteswapper-logo .nav-logo, .nav--sub .siteswapper .siteswapper-logo svg, .siteswapper .nav--sub .siteswapper-logo .nav-logo, .siteswapper .nav--sub .siteswapper-logo svg {
    margin: 0;
  }
}

@media (max-width: 950px) {
  .nav-wrapper > *, .siteswapper .siteswapper-logo > * {
    z-index: 2;
  }

  .nav-wrapper:before, .siteswapper .siteswapper-logo:before {
    content: "";
    transform-origin: top;
    border-radius: 100%;
    width: 100%;
    height: 0;
    transition: all .35s ease-out;
    position: absolute;
    top: -500px;
    left: 0;
  }

  .inbounz .nav-wrapper:before, .inbounz .siteswapper .siteswapper-logo:before, .siteswapper .inbounz .siteswapper-logo:before {
    background: #ffe800;
    box-shadow: 0 0 40px rgba(0, 0, 0, .2);
  }

  .outbounz .nav-wrapper:before, .outbounz .siteswapper .siteswapper-logo:before, .siteswapper .outbounz .siteswapper-logo:before {
    background: #000;
    box-shadow: 0 0 40px rgba(255, 232, 0, .2);
  }

  .nav--opened .nav-wrapper:before, .nav--opened .siteswapper .siteswapper-logo:before, .siteswapper .nav--opened .siteswapper-logo:before {
    border-radius: 0;
    height: calc(100% + 100vh - 100px);
    top: 0;
  }
}

.nav-logo, .siteswapper .siteswapper-logo svg {
  z-index: 2;
  height: 36px;
  margin-right: 25px;
  display: inline-block;
  position: relative;
}

.nav-logo svg, .siteswapper .siteswapper-logo svg svg, .nav-logo img, .siteswapper .siteswapper-logo svg img {
  width: auto;
  height: 100%;
}

.nav-logo .logo-prim, .siteswapper .siteswapper-logo svg .logo-prim {
  fill: #ffe800;
}

.nav-logo .logo-sec, .siteswapper .siteswapper-logo svg .logo-sec {
  fill: #000;
}

.inbounz .nav-logo.nav-logo--invert .logo-prim, .inbounz .siteswapper .siteswapper-logo svg .logo-prim, .siteswapper .siteswapper-logo .inbounz svg .logo-prim, .outbounz .nav-logo.nav-logo--invert .logo-sec, .outbounz .siteswapper .siteswapper-logo svg .logo-sec, .siteswapper .siteswapper-logo .outbounz svg .logo-sec {
  fill: #fff;
}

.nav-logo:hover .logo-prim, .siteswapper .siteswapper-logo svg:hover .logo-prim {
  animation: .35s ease-in-out logo-x;
}

.nav-logo:hover .logo-prim--circle, .siteswapper .siteswapper-logo svg:hover .logo-prim--circle {
  animation: .35s ease-in-out logo-y;
}

.nav-logo.nav-logo--big, .siteswapper .siteswapper-logo svg.nav-logo--big {
  height: 75px;
  margin: 25px;
}

.nav-icon {
  z-index: 2;
  height: 60px;
  margin-right: 25px;
  display: block;
  position: relative;
}

.nav-icon svg, .nav-icon img {
  width: auto;
  height: 100%;
}

.inbounz .nav-icon .icon-bg {
  fill: #ffe800;
}

.outbounz .nav-icon .icon-bg {
  fill: #000;
}

.inbounz .nav-icon .icon-prim {
  fill: #fff;
}

.outbounz .nav-icon .icon-prim {
  fill: #ffe800;
}

.inbounz .nav-icon .icon-sec {
  fill: #000;
}

.outbounz .nav-icon .icon-sec {
  fill: #fff;
}

.nav-icon:last-child {
  margin-right: 0;
}

.inbounz .nav-icon.nav-icon--invert .icon-bg {
  fill: #000;
}

.outbounz .nav-icon.nav-icon--invert .icon-bg, .inbounz .nav-icon.nav-icon--invert .icon-prim {
  fill: #ffe800;
}

.outbounz .nav-icon.nav-icon--invert .icon-prim, .inbounz .nav-icon.nav-icon--invert .icon-sec {
  fill: #fff;
}

.outbounz .nav-icon.nav-icon--invert .icon-sec {
  fill: #000;
}

.nav-icon:hover .icon-prim {
  animation: .35s ease-in-out logo-x;
}

.nav-icon:hover .icon-prim--circle {
  animation: .35s ease-in-out logo-y;
}

@keyframes logo-x {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  50% {
    opacity: 0;
    transform: translateX(-50px);
  }

  50.1% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes logo-y {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  50% {
    opacity: 0;
    transform: translateY(-50px);
  }

  50.1% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media (max-width: 950px) {
  .nav-items {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    transition: opacity .35s ease-in-out;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 50px;
    left: 0;
    overflow-y: auto;
  }

  .nav--opened .nav-items {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    height: calc(100vh - 100px);
  }

  .nav--sub .nav-items {
    text-align: center;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: start;
    justify-content: flex-start;
    display: -ms-flexbox;
    display: flex;
    position: static;
  }
}

@media (max-width: 680px) {
  .nav--sub .nav-items {
    display: block;
  }
}

.nav-items .nav-item {
  margin: 0;
  display: inline-block;
  position: relative;
}

@media (max-width: 950px) {
  .nav-items .nav-item {
    margin: 10px 0;
  }
}

.nav-items .nav-item > a {
  padding: 10px 20px;
  transition: color .2s ease-in;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.nav-items .nav-item > a:before {
  content: "";
  z-index: -1;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  transition: transform .1s ease-out .2s, width .2s ease-in;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.inbounz .nav-items .nav-item > a:before {
  background: #000;
}

.outbounz .nav-items .nav-item > a:before, .inbounz .nav--sub .nav-items .nav-item > a:before {
  background: #ffe800;
}

.outbounz .nav--sub .nav-items .nav-item > a:before {
  background: #000;
}

.nav-items .nav-item:hover > a {
  transition: color .2s ease-out .1s;
}

.inbounz .nav-items .nav-item:hover > a {
  color: #ffe800;
}

.outbounz .nav-items .nav-item:hover > a, .inbounz .nav--sub .nav-items .nav-item:hover > a {
  color: #000;
}

.outbounz .nav--sub .nav-items .nav-item:hover > a {
  color: #ffe800;
}

.nav-items .nav-item:hover > a:before {
  width: 100%;
  transition: transform .1s ease-in, width .1s ease-out .2s;
  transform: translateY(0);
}

@media (max-width: 950px) {
  .nav-items .nav-item {
    text-align: center;
  }

  .inbounz .nav-items .nav-item > a.active {
    color: #ffe800;
  }

  .outbounz .nav-items .nav-item > a.active {
    color: #000;
  }

  .nav-items .nav-item > a.active:before {
    width: 100%;
    transition: transform .1s ease-in, width .1s ease-out .2s;
    transform: translateY(0);
  }
}

.nav-items .nav-languages-wrapper {
  display: block;
  position: absolute;
  top: 30px;
  right: 50px;
}

.nav--fixed:not(.nav--nocta) .nav-items .nav-languages-wrapper {
  display: none;
}

@media (max-width: 1020px) {
  .nav-items .nav-languages-wrapper {
    top: 10px;
    right: 25px;
  }
}

@media (max-width: 950px) {
  .nav-items .nav-languages-wrapper {
    display: none;
  }

  .nav--opened .nav-items .nav-languages-wrapper {
    margin: 10px 0 25px;
    display: block;
    position: static;
  }
}

.nav-items .nav-cta {
  display: none;
  position: absolute;
  top: 30px;
  right: 50px;
}

.nav--fixed:not(.nav--nocta) .nav-items .nav-cta {
  display: block;
}

.nav-items .nav-cta > a {
  margin: 0;
}

.inbounz .nav-items .nav-cta > a {
  color: #ffe800;
}

.outbounz .nav-items .nav-cta > a {
  color: #000;
}

@media (max-width: 1020px) {
  .nav-items .nav-cta {
    top: 10px;
    right: 25px;
  }
}

@media (max-width: 950px) {
  .nav-items .nav-cta {
    margin: 10px 0 25px;
    display: block;
    position: static;
  }
}

.nav-subitems {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 9;
  width: 275px;
  margin: 0;
  padding: 22px 25px 10px;
  list-style-type: none;
  transition: all .25s ease-in;
  position: absolute;
  top: 40px;
  left: -5px;
  transform: translateY(-25px);
}

.inbounz .nav-subitems {
  color: #fff;
}

.outbounz .nav-subitems {
  color: #000;
}

.nav-subitems:before {
  content: "";
  z-index: -1;
  border-radius: 20px;
  width: 100%;
  height: calc(100% - 10px);
  position: absolute;
  top: 10px;
  left: 0;
  box-shadow: 0 3px 3px rgba(0, 0, 0, .5);
}

.inbounz .nav-subitems:before {
  background: #000;
}

.outbounz .nav-subitems:before {
  background: #ffe800;
}

@media (min-width: 951px) {
  li:hover > .nav-subitems {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    transition: all .25s ease-out;
    transform: translateX(0);
  }
}

@media (max-width: 950px) {
  .nav-subitems {
    visibility: visible;
    pointer-events: auto;
    transition: opacity .35s ease-in-out;
    display: none;
    position: relative;
    top: 0;
    overflow: hidden;
    transform: none;
  }

  .nav-subitems:before {
    height: 100%;
    top: 0;
    box-shadow: inset 0 -3px 6px rgba(255, 255, 255, .5);
  }

  .inbounz .nav-subitems:before {
    background: #ffe800;
  }

  .outbounz .nav-subitems:before {
    background: #000;
  }

  .active + .nav-subitems {
    opacity: 1;
  }
}

.nav-subitems .nav-subitem {
  transition: all .25s ease-out;
  display: block;
}

.nav-subitems .nav-subitem > a {
  padding: 10px 0;
  font-weight: normal;
}

.inbounz .nav-subitems .nav-subitem > a {
  color: #ffe800;
}

.outbounz .nav-subitems .nav-subitem > a {
  color: #000;
}

@media (max-width: 950px) {
  .inbounz .nav-subitems .nav-subitem > a {
    color: #000;
  }

  .outbounz .nav-subitems .nav-subitem > a {
    color: #ffe800;
  }
}

.nav-subitems .nav-subitem:hover {
  transform: translateX(5px);
}

.nav-languages {
  text-align: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.nav-languages li {
  margin: 0;
  display: inline-block;
  position: relative;
}

.nav-languages li > a {
  width: 40px;
  height: 40px;
  padding: 10px 0;
  transition: all .2s ease-out;
  display: inline-block;
}

.inbounz .nav-languages li.active > a, .outbounz .nav-languages li.active > a {
  color: #000;
}

.inbounz .nav-languages li:hover > a {
  color: #ffe800;
}

.outbounz .nav-languages li:hover > a {
  color: #000;
}

.nav-languages li:before {
  content: "";
  z-index: -1;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  transition: transform .2s ease-out, background .2s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

.inbounz .nav--sub .nav-languages li:before {
  background: #ffe800;
}

.outbounz .nav--sub .nav-languages li:before {
  background: #000;
}

.nav-languages li.active:before {
  transform: scale(1);
}

.inbounz .nav-languages li.active:before {
  background: #fff;
}

.outbounz .nav-languages li.active:before {
  background: #ffe800;
}

.nav-languages li:hover:before {
  transform: scale(1);
}

.inbounz .nav-languages li:hover:before {
  background: #000;
}

.outbounz .nav-languages li:hover:before {
  background: #fff;
}

.nav-languages:hover li:after {
  opacity: 1;
}

.nav-burger {
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: none;
  position: absolute;
  top: 5px;
  right: 15px;
  overflow: hidden;
}

@media (max-width: 950px) {
  .nav-burger {
    display: block;
  }
}

.nav-burger span {
  border-radius: 2px;
  width: 20px;
  height: 4px;
  transition: all .2s ease-in-out;
  position: absolute;
  right: 10px;
}

.inbounz .nav-burger span {
  background: #000;
}

.outbounz .nav-burger span {
  background: #fff;
}

.nav-burger span:before {
  content: "";
  border-radius: 2px;
  width: 4px;
  height: 4px;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 0;
}

.inbounz .nav-burger span:before {
  background: #000;
}

.outbounz .nav-burger span:before {
  background: #fff;
}

.nav-burger span:first-child {
  top: 13px;
  right: 10px;
}

.nav-burger span:first-child:before {
  left: -8px;
}

.nav-burger span:nth-child(2) {
  top: 23px;
  right: 20px;
}

.nav-burger span:nth-child(2):before {
  right: -8px;
}

.nav-burger span:nth-child(3) {
  top: 33px;
  right: 10px;
}

.nav-burger span:nth-child(3):before {
  left: -8px;
}

.nav-burger:hover span:first-child {
  right: 20px;
}

.nav-burger:hover span:first-child:before {
  left: calc(100% + 4px);
}

.nav-burger:hover span:nth-child(2) {
  right: 10px;
}

.nav-burger:hover span:nth-child(2):before {
  right: calc(100% + 4px);
}

.nav-burger:hover span:nth-child(3) {
  right: 20px;
}

.nav-burger:hover span:nth-child(3):before {
  left: calc(100% + 4px);
}

.nav--opened .nav-burger span:first-child {
  transform-origin: center;
  width: 28px;
  top: 23px;
  right: 11px;
  transform: rotate(45deg);
}

.nav--opened .nav-burger span:first-child:before {
  left: 0;
}

.nav--opened .nav-burger span:nth-child(2) {
  width: 4px;
  right: calc(50% - 2px);
}

.nav--opened .nav-burger span:nth-child(3) {
  transform-origin: center;
  width: 28px;
  top: 23px;
  right: 11px;
  transform: rotate(-45deg);
}

.nav--opened .nav-burger span:nth-child(3):before {
  left: 0;
}

.nav-info {
  line-height: 50px;
  position: absolute;
  right: 50px;
}

@media (max-width: 1020px) {
  .nav-info {
    right: 25px;
  }
}

@media (max-width: 680px) {
  .nav-info {
    position: static;
  }
}

.doc {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 100px 50px 0 calc(33.3333% + 50px);
  position: relative;
}

@media (max-width: 1020px) {
  .doc {
    padding: 60px 25px 0 calc(33.3333% + 25px);
  }
}

@media (max-width: 680px) {
  .doc {
    padding: 60px 25px 0;
  }
}

@media (min-width: 1920px) {
  .doc {
    padding: 100px 50px 0 650px;
  }
}

.doc figure {
  text-align: center;
  margin: 50px 0;
}

@media (max-width: 1020px) {
  .doc figure {
    margin: 25px 0;
  }
}

.doc img {
  width: auto;
  max-width: 100%;
  max-height: calc(80vh - 100px);
  display: inline-block;
  box-shadow: 0 0 3px rgba(0, 0, 0, .5);
}

@media (max-width: 1020px) {
  .doc img {
    max-height: calc(80vh - 60px);
  }
}

.doc pre {
  white-space: break-spaces;
  background: #000;
  width: 100%;
  margin: 1em 0;
  padding: 8px;
  overflow-x: auto;
}

.doc code {
  border-radius: 3px;
  width: 100%;
  padding: 1px 4px;
  font-family: monospace;
  font-size: 16px;
}

.inbounz .doc code {
  color: #fff;
  background: #000;
}

.outbounz .doc code {
  color: #000;
  background: #ffe800;
}

.doc .table-wrapper {
  width: 100%;
  margin: 1em 0;
  overflow-x: auto;
}

.doc table {
  border-collapse: collapse;
  border: 1px solid #eee;
  width: 100%;
}

.doc th, .doc td {
  border: 1px solid #eee;
  padding: 8px;
  line-height: 22px;
}

.doc th {
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: .875rem;
  font-weight: normal;
}

.inbounz .doc th {
  color: #000;
  background: #ffe800;
}

.outbounz .doc th {
  color: #fff;
  background: #000;
}

.doc blockquote {
  margin: 1em 0;
  padding: 25px;
}

.inbounz .doc blockquote {
  color: #000;
  background: #ffe800;
}

.outbounz .doc blockquote {
  color: #fff;
  background: #000;
}

.doc h4 {
  margin-top: 2em;
}

.doc h4 + blockquote {
  margin-top: 0;
}

.doc .nav-wrapper, .doc .siteswapper .siteswapper-logo, .siteswapper .doc .siteswapper-logo {
  padding-left: 0;
  padding-right: 0;
}

.doc-content {
  padding-top: 100px;
}

@media (max-width: 1020px) {
  .doc-content {
    padding-top: 60px;
  }
}

.doc-content ul, .doc-content ol {
  counter-reset: list-counter;
  padding-left: 2em;
  list-style-type: none;
}

.doc-content li {
  counter-increment: list-counter;
}

.doc-content ul > li:before, .doc-content ol > li:before {
  color: #ffe800;
  margin-left: -1em;
  font-weight: bold;
  display: inline-block;
  position: absolute;
}

.doc-content ul > li:before {
  content: "•";
  font-size: 1.25em;
  line-height: .8em;
}

.doc-content ol > li:before {
  content: counter(list-counter) ".";
  font-size: 1em;
  line-height: inherit;
}

.video {
  background: rgba(0, 0, 0, .07);
  border: 10px solid #ffe800;
  width: 100%;
  max-width: calc(142.222vh - 177.778px);
  margin: 0 auto;
  position: relative;
}

.container-wrapper--color .video {
  background: rgba(255, 255, 255, .5);
}

.outbounz .container-wrapper--black .container-wrapper--color .video, .inbounz .container-wrapper--color .container-wrapper--color .video {
  border-color: #fff;
}

@media (max-width: 1020px) {
  .video {
    max-width: calc(142.222vh - 106.667px);
  }
}

.video-wrapper {
  pointer-events: none;
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}

.video-wrapper iframe {
  pointer-events: all;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-consent {
  text-align: center;
  color: #000;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.video-consent > div {
  max-height: 100%;
  margin: auto;
  padding: 50px;
  overflow-y: auto;
}

@media (max-width: 680px) {
  .video-consent > div {
    padding: 25px;
  }
}

.doc-nav {
  z-index: 9;
  width: 33%;
  height: calc(100% - 100px);
  padding: 25px;
  transition: all .35s ease-out;
  position: fixed;
  top: 100px;
  left: 0;
  overflow-y: auto;
}

.inbounz .doc-nav {
  color: #000;
  background-color: #ffe800;
}

.outbounz .doc-nav {
  color: #fff;
  background-color: #000;
}

@media (max-width: 1020px) {
  .doc-nav {
    height: calc(100% - 60px);
    top: 60px;
  }
}

@media (min-width: 1920px) {
  .doc-nav {
    width: 600px;
    padding: 50px;
  }
}

@media (max-width: 680px) {
  .doc-nav {
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    padding-bottom: 80px;
    top: 60px;
    transform: translateX(-100%);
  }

  .doc-nav.doc-nav--opened {
    transform: translateX(0);
  }
}

.doc-nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.doc-nav ul li {
  margin: 0;
  padding: 5px 0;
}

.doc-nav ul li a {
  border: 0;
  font-weight: normal;
  text-decoration: none;
}

.inbounz .doc-nav ul li a span {
  color: #fff;
  font-weight: bold;
}

.outbounz .doc-nav ul li a span {
  color: #ffe800;
}

.doc-nav ul li a:hover {
  text-decoration: underline;
}

.doc-nav ul li a:hover span {
  text-decoration: none;
}

.doc-nav ul ul {
  padding-left: 25px;
}

.doc-nav > div > ul > li {
  padding-bottom: 15px;
}

.doc-nav > div > ul > li:last-child {
  padding-bottom: 0;
}

.doc-nav-icon {
  z-index: 9;
  cursor: pointer;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #ffe800;
  padding: 10px 20px 10px 30px;
  font-size: .875rem;
  font-style: italic;
  font-weight: normal;
  transition: all .35s ease-out;
  position: fixed;
  bottom: 20px;
  left: -10px;
  transform: skewX(-14deg);
  box-shadow: 0 0 40px rgba(0, 0, 0, .2);
}

.doc-nav-icon > * {
  display: inline-block;
  transform: skewX(14deg);
}

@media (min-width: 681px) {
  .doc-nav-icon {
    display: none;
  }
}

.doc-nav-icon .material-icons:first-child {
  display: none;
}

.doc-nav-icon .doc-nav-close {
  margin-right: 10px;
  display: none;
}

.doc-nav--opened + .doc-nav-icon {
  color: #ffe800;
  background-color: #000;
  border: 1px solid #ffe800;
}

.doc-nav--opened + .doc-nav-icon .doc-nav-close {
  display: inline-block;
}

.footer-wrapper {
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: end;
  align-items: flex-end;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media (max-width: 1020px) {
  .footer-wrapper {
    padding: 25px;
  }
}

@media (max-width: 680px) {
  .footer-wrapper {
    text-align: center;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.footer-wrapper > div {
  width: calc(50% - 25px);
}

@media (max-width: 680px) {
  .footer-wrapper > div {
    width: 100%;
    margin-top: 25px;
  }
}

.footer-wrapper p:last-child {
  margin-bottom: 0;
}

.blog {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 150px 50px 50px;
  position: relative;
}

@media (max-width: 1020px) {
  .blog {
    padding: 85px 25px 25px;
  }
}

.blog-overview {
  width: 100%;
  max-width: none;
  margin: 0 auto;
  padding: 150px 0 50px;
  position: relative;
}

@media (max-width: 1020px) {
  .blog-overview {
    padding: 85px 0 25px;
  }
}

.blog-teasers {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

.blog-teaser {
  width: 300px;
  max-width: calc(100% - 30px);
  margin: 15px;
  position: relative;
}

.blog-teaser .button {
  margin-top: 15px;
  margin-bottom: 0;
}

.blog-teaser.blog-teaser--withbg:before {
  content: "";
  z-index: -1;
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-teaser.blog-teaser--withbg .blog-teaser-text {
  padding: 15px;
}

.blog-teaser-text {
  padding-top: 15px;
}

@media (max-width: 1020px) {
  .slick-slide {
    padding-left: 25px;
    padding-right: 25px;
  }
}

[class^="slick-arrow"] {
  cursor: pointer;
  z-index: 2;
  border-radius: 2px;
  width: 50px;
  height: 100%;
  transition: transform .2s ease-in-out;
  position: absolute;
  top: 0;
}

[class^="slick-arrow"]:before {
  content: "";
  background: #000;
  border-radius: 2px;
  width: 24px;
  height: 4px;
  position: absolute;
  top: calc(50% - 2px);
}

.outbounz .container-wrapper--color [class^="slick-arrow"]:before, .inbounz .container-wrapper--black [class^="slick-arrow"]:before {
  background: #fff;
}

[class^="slick-arrow"]:after {
  content: "";
  background: #ffe800;
  border-radius: 2px;
  width: 4px;
  height: 4px;
  position: absolute;
  top: calc(50% - 2px);
}

.outbounz .container-wrapper--black [class^="slick-arrow"]:after, .inbounz .container-wrapper--color [class^="slick-arrow"]:after {
  background: #fff;
}

[class^="slick-arrow"] span {
  position: absolute;
  top: 50%;
}

[class^="slick-arrow"] span:after, [class^="slick-arrow"] span:before {
  content: "";
  background: #000;
  border-radius: 2px;
  width: 12px;
  height: 4px;
  transition: transform .2s ease-in-out;
  position: absolute;
  top: -2px;
}

.outbounz .container-wrapper--color [class^="slick-arrow"] span:after, .inbounz .container-wrapper--black [class^="slick-arrow"] span:after, .outbounz .container-wrapper--color [class^="slick-arrow"] span:before, .inbounz .container-wrapper--black [class^="slick-arrow"] span:before {
  background: #fff;
}

[class^="slick-arrow"] span:after {
  transform: rotate(-40deg);
}

[class^="slick-arrow"] span:before {
  transform: rotate(40deg);
}

[class^="slick-arrow"]:hover {
  width: 60px;
}

[class^="slick-arrow"]:hover span:after {
  transform: rotate(40deg);
}

[class^="slick-arrow"]:hover span:before {
  transform: rotate(-40deg);
}

.slick-arrow-left {
  left: 50px;
}

.slick-arrow-left:hover {
  transform: translateX(-10px);
}

@media (max-width: 1020px) {
  .slick-arrow-left {
    left: 15px;
  }
}

.slick-arrow-left:before {
  left: 8px;
}

.slick-arrow-left:after, .slick-arrow-left span {
  left: 0;
}

.slick-arrow-left span:after, .slick-arrow-left span:before {
  transform-origin: -8px;
  left: 8px;
}

.slick-arrow-right {
  right: 50px;
}

.slick-arrow-right:hover {
  transform: translateX(10px);
}

@media (max-width: 1020px) {
  .slick-arrow-right {
    right: 15px;
  }
}

.slick-arrow-right:before {
  right: 8px;
}

.slick-arrow-right:after, .slick-arrow-right span {
  right: 0;
}

.slick-arrow-right span:after, .slick-arrow-right span:before {
  transform-origin: 20px;
  right: 8px;
}

.siteswapper {
  z-index: 11;
  width: 315px;
  height: 100%;
  transition: all .35s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%)translateX(15px);
}

.siteswapper .siteswapper-logo {
  pointer-events: none;
  border: 0;
  height: 100px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1020px) {
  .siteswapper .siteswapper-logo {
    height: 60px;
  }
}

.siteswapper .siteswapper-logo svg {
  width: auto;
}

.outbounz .siteswapper .siteswapper-logo svg .logo-prim {
  fill: #fff;
}

.inbounz .siteswapper .siteswapper-logo svg .logo-prim {
  fill: #ffe800;
}

.outbounz .siteswapper .siteswapper-logo svg .logo-sec {
  fill: #000;
}

.inbounz .siteswapper .siteswapper-logo svg .logo-sec {
  fill: #fff;
}

.siteswapper .siteswapper-wrapper {
  height: 100%;
}

.siteswapper > .siteswapper-wrapper {
  width: calc(100% - 15px);
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);
}

.siteswapper > .siteswapper-wrapper .container {
  transition: all .35s ease-out;
}

.siteswapper .siteswapper-icon {
  cursor: pointer;
  border-radius: 50%;
  transition: all .35s ease-out;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-15px, -50%);
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);
}

.inbounz .siteswapper .siteswapper-icon {
  box-shadow: 0 0 5px rgba(255, 232, 0, .75);
}

.inbounz .siteswapper .siteswapper-icon svg .icon-bg {
  fill: #000;
}

.outbounz .siteswapper .siteswapper-icon svg .icon-bg, .inbounz .siteswapper .siteswapper-icon svg .icon-prim {
  fill: #ffe800;
}

.outbounz .siteswapper .siteswapper-icon svg .icon-prim, .inbounz .siteswapper .siteswapper-icon svg .icon-sec {
  fill: #fff;
}

.outbounz .siteswapper .siteswapper-icon svg .icon-sec {
  fill: #000;
}

.siteswapper.siteswapper--show {
  transform: translateX(-100%)translateX(15px);
}

.siteswapper.siteswapper--show .siteswapper-icon {
  transform: translate(50%, -50%);
}

.siteswapper.siteswapper--opened, .siteswapper:hover {
  transform: translateX(0);
}

.siteswapper.siteswapper--opened .siteswapper-logo, .siteswapper:hover .siteswapper-logo {
  pointer-events: all;
}

.siteswapper.siteswapper--opened .siteswapper-icon, .siteswapper:hover .siteswapper-icon {
  transform: translate(50%, -50%)translateX(-15px);
}

.siteswapper.siteswapper--extended {
  pointer-events: none;
  width: calc(100% + 15px);
  transform: translateX(0);
}

.siteswapper.siteswapper--extended .siteswapper-icon {
  transform: translate(100%, -50%);
}

.siteswapper.siteswapper--extended .container {
  opacity: 0;
}

@media (min-width: 1200px) {
  .siteswapper.siteswapper--extended .siteswapper-logo {
    left: calc(50vw - 600px);
  }
}

.u-center {
  text-align: center;
}

.u-left {
  text-align: left;
}

.u-right {
  text-align: right;
}

.u-justify {
  text-align: justify;
}

@media (max-width: 1020px) {
  .u-justify {
    text-align: left;
  }
}

.u-nomargin {
  margin: 0 !important;
}

.u-nomargin-top {
  margin-top: 0 !important;
}

.u-nomargin-bottom {
  margin-bottom: 0 !important;
}

.u-nomargin-left {
  margin-left: 0 !important;
}

.u-nomargin-right {
  margin-right: 0 !important;
}

.u-nopadding {
  padding: 0 !important;
}

.u-nopadding-top {
  padding-top: 0 !important;
}

.u-nopadding-bottom {
  padding-bottom: 0 !important;
}

.u-nopadding-left {
  padding-left: 0 !important;
}

.u-nopadding-right {
  padding-right: 0 !important;
}

.u-padding-top {
  padding-top: 50px;
}

@media (max-width: 1020px) {
  .u-padding-top {
    padding-top: 25px;
  }
}

.u-bigpadding-top {
  padding-top: 100px;
}

@media (max-width: 1020px) {
  .u-bigpadding-top {
    padding-top: 50px;
  }
}

.u-padding-bottom {
  padding-bottom: 50px;
}

@media (max-width: 1020px) {
  .u-padding-bottom {
    padding-bottom: 25px;
  }
}

.u-bigpadding-bottom {
  padding-bottom: 100px;
}

@media (max-width: 1020px) {
  .u-bigpadding-bottom {
    padding-bottom: 75px;
  }
}

.u-margin-top {
  margin-top: 50px;
}

@media (max-width: 1020px) {
  .u-margin-top {
    margin-top: 25px;
  }
}

.u-bigmargin-top {
  margin-top: 100px;
}

@media (max-width: 1020px) {
  .u-bigmargin-top {
    margin-top: 50px;
  }
}

.u-margin-bottom {
  margin-bottom: 50px;
}

@media (max-width: 1020px) {
  .u-margin-bottom {
    margin-bottom: 25px;
  }
}

.u-bigmargin-bottom {
  margin-bottom: 100px;
}

@media (max-width: 1020px) {
  .u-bigmargin-bottom {
    margin-bottom: 75px;
  }
}

.u-color-corporate {
  color: #ffe800;
}

.u-bgcolor-corporate {
  background-color: #ffe800;
}

.u-color-grey {
  color: #eee;
}

.u-bgcolor-grey {
  background-color: #eee;
}

.u-color-white {
  color: #fff;
}

.u-bgcolor-white {
  background-color: #fff;
}

.u-color-black {
  color: #000;
}

.u-bgcolor-black {
  background-color: #000;
}

/*# sourceMappingURL=app.css.map */
