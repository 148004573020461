html {
    font-size: 1rem;
    font-family: sofia-pro-soft, serif;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    position: relative;
    overflow-x: hidden;
    margin: 0;
    scroll-behavior: smooth;
}

main {
    position: relative;
    width: 100%;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    font-weight: 700;
    color: inherit;
    border-bottom: 2px solid color("corporate");
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    transition: border .2s ease-out;
    &:hover {
        border-bottom-width: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

p {
    margin: 0.75rem 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

ul,
ol,
li>ol,
li>ul {
    margin-bottom: 0.75rem;
}

ul,
ol {
    :not(li)>&:last-child {
        margin-bottom: 0;
    }
}

li {
    margin: 0 0 .75rem;
}

hr {
    display: block;
    margin: 0;
    width: 100%;
    height: 4px;
    border: none;
    outline: none;
    background-size: contain;
    background-repeat: repeat-x;
    background-position: left center;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 4"%3E%3Ccircle fill="black" cx="2" cy="2" r="2"/%3E%3Crect fill="black" x="6.33" width="11.33" height="4" rx="2"/%3E%3C/svg%3E');

    .inbounz .container-wrapper--black &,
    .outbounz .container-wrapper--color & {
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 4"%3E%3Ccircle fill="#{color("white")}" cx="2" cy="2" r="2"/%3E%3Crect fill="white" x="6.33" width="11.33" height="4" rx="2"/%3E%3C/svg%3E');
    }
}

img,
svg {
    width: 100%;
    max-width: 100%;

    &.portrait {
	    display: inline-block;
        max-width: 125px;
        margin-bottom: 1rem;
        border-radius: 50%;
        background: rgba(0, 0, 0, .07);
        border: 10px solid map-get($colors, "corporate");

        .inbounz .container-wrapper--color &,
        .outbounz .container-wrapper--black & {
            background: rgba(255, 255, 255, .5);
            border-color: color("white");
        }
    }
}

figure {
    position: relative;
    padding: 0;
    margin: 1.5rem 0;

    &.can-fullscreen {
        cursor: pointer;
    }

    &.can-fullscreen.is-fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        background: rgba(0, 0, 0, .8);
        z-index: 999;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .figure-close {
            display: block;
        }

        img {
            width: 100%;
            height: auto;
            max-width: 100%;
            max-height: 100vh;
            object-fit: contain
        }
    }

    .figure-close {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(255, 255, 255, .5);
        padding: 5px;
        font-style: italic;
        font-weight: bold;
        line-height: 30px;
        width: 40px;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        color: map-get($colors, "darkgrey");
    }
}

figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    background: rgba(255, 255, 255, .5);
    padding: 5px;
    font-style: italic;
    color: map-get($colors, "darkgrey");
}

* {

    &,
    &:before,
    &:after {
        box-sizing: inherit;
    }

}

.text-icon {
    width: 1em;
    height: 1em;
    display: inline-flex;
    border-radius: 50%;
    padding: 0.25em;
    align-items: center;
    justify-content: center;
    margin-right: 0.125em;
    transform: translateY(0.125em);
    .inbounz & {
        background: color("corporate");
        color: color("black");
    }
    .outbounz & {
        background: color("black");
        color: color("white");
    }
    svg {
        height: 1em;
    }
}

.container-wrapper {
    position: relative;
    min-height: calc(100vh - 100px);

    @media #{map-get($mediaqueries, "medium-down")} {
        min-height: calc(100vh - 50px);
    }

    &.container-wrapper--slim {
        min-height: auto;
    }

    &.container-wrapper--clipped {
        overflow: hidden;
    }

    &.container-wrapper--fullheight {
        min-height: 100vh;
        display: flex;
    }
}

.container-wrapper--color,
.container-wrapper--black,
.container-wrapper--grey {
    position: relative;
}

@mixin container($max-width: none, $withSidePadding: true) {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: $max-width;

    @if ($withSidePadding) {
        padding: #{$navHeight + 50px} 50px 50px;

        @media #{map-get($mediaqueries, "medium-down")} {
            padding: #{$navHeightMedium + 25px} 25px 25px;
        }
    }

    @else {
        padding: #{$navHeight + 50px} 0 50px;

        @media #{map-get($mediaqueries, "medium-down")} {
            padding: #{$navHeightMedium + 25px} 0 25px;
        }
    }
}

.container {
    @include container($containerWidth);
    min-height: 100vh;
    display: flex;
    align-items: center;

    @media #{map-get($mediaqueries, "small-down")} {
        min-height: 40vh;

        .container-wrapper--center & {
            min-height: 100vh;
        }
    }

    .outbounz .container-wrapper--black &,
    .inbounz .container-wrapper--color & {
        color: color("black");

        a:not(.button) {
            color: color("black");
            border-color: color("white");
        }

        h4 {
            color: color("black");
        }
    }

    .outbounz .container-wrapper--color &,
    .inbounz .container-wrapper--black & {
        color: color("white");

        a:not(.button) {
            color: color("white");
        }
    }

    .container-wrapper--slim & {
        min-height: auto;
        justify-content: center;
    }

    &.container--slim {
        padding: 50px;

        @media #{map-get($mediaqueries, "medium-down")} {
            padding: 25px;
        }
    }

    &.container--fullwidth {
        max-width: none;
    }
}

.container-bgcolor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    pointer-events: none;
    clip-path: polygon(0 0,100% 0,100% 100%,0 100%);

    .outbounz .container-wrapper--black &,
    .inbounz .container-wrapper--color & {
        background-color: color("corporate");
    }

    .outbounz .container-wrapper--color &,
    .inbounz .container-wrapper--black & {
        background-color: color("black");
    }

    .container-wrapper--grey & {
        background-color: color("grey");
    }
}

.container-bgcolor-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    mix-blend-mode: soft-light;
}

.container-bg {
    position: absolute;
    display: flex;
    align-items: center;
    top: 100px;
    left: 50%;
    width: 50%;
    height: calc(100% - $navHeight);
    z-index: 0;
    pointer-events: none;
    overflow: hidden;

    >img {
        display: block;
        width: 125%;
        height: 100%;
        max-width: none;
        object-fit: contain;
    }

    @media #{map-get($mediaqueries, "medium-down")} {
        top: $navHeightMedium;
        height: calc(100% - $navHeightMedium);
    }

    @media #{map-get($mediaqueries, "small-down")} {
        position: static;
        height: auto;
        width: 100%;
        padding: 25px 0 50px 0;

        >img {
            position: relative;
            left: 20px;
            width: calc(100% - 40px);
        }
    }

    &.container-bg--fullpage {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        filter: grayscale(50%) brightness(80%);
        mix-blend-mode: luminosity;

        >div {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
        }

        @media #{map-get($mediaqueries, "medium-down")} {
            top: 0;
            height: 100%;
        }

        @media #{map-get($mediaqueries, "small-down")} {
            position: absolute;
            height: 100%;
            width: 100%;
        }
    }
}

.container-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    >.button,
    >.button:hover {
        margin-top: 0;
        margin-left: 10px;
        margin-right: 10px;
        color: color("black");
    }
}

.container-video {
    position: absolute;
    top: 100px;
    left: 50%;
    width: 50%;
    height: calc(100% - 100px);
    overflow: hidden;
    display: flex;
    align-items: center;
    padding-left: 50px;

    @media #{map-get($mediaqueries, "medium-down")} {
        top: 50px;
        height: calc(100% - 50px);
        padding-left: 25px;
    }

    @media #{map-get($mediaqueries, "small-down")} {
        position: static;
        width: 100%;
        height: auto;
        padding-left: 0;
    }
}

.container-heading {
    width: 50%;

    .container-wrapper--center & {
        width: 100%;
        text-align: center;
        max-width: 650px;
        margin: 0 auto;
    }

    &.container-heading--fullpage {
        width: 100%;
        max-width: 550px;
    }

    @media #{map-get($mediaqueries, "small-down")} {
        width: 100%;
    }
}

.container-imgtext {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 875px;

    > :first-child {
        width: 25%;
        padding-right: 25px;
        padding-left: 0;
    }

    > :last-child {
        width: 75%;
    }

    &.container-imgtext--inverted {
        text-align: right;
        justify-content: flex-end;

        > :first-child {
            order: 2;
            padding-right: 0;
            padding-left: 25px;
        }
    }

    @media #{map-get($mediaqueries, "small-down")} {

	    > :first-child {
	        width: 35%;
	    }

	    > :last-child {
	        width: 65%;
	    }
	}

    @media #{map-get($mediaqueries, "xsmall-down")} {
        flex-direction: column;
        align-items: flex-start;

	    > :first-child {
	        width: 100%;
	        padding-right: 25px;
	        padding-left: 25px;
	        padding-bottom: 25px;
	        text-align: center;
	        img {
	        	display: inline-block;
	        	max-width: 175px;
	        }
	    }

	    > :last-child {
	        width: 100%;
	        text-align: center;
	    }

        &.container-imgtext--inverted {
            align-items: flex-end;

            > :first-child {
                order: 0;
            }
        }
    }
}

.container-img {
    width: auto;
    max-height: 50vh;

    @media #{map-get($mediaqueries, "medium-down")} {
        margin-bottom: 50px;
    }
}

.container-icon {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, 50%);
}

.container-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 25px;

    .outbounz .container-wrapper--black &,
    .inbounz .container-wrapper--color & {
        color: color("black");
    }

    .outbounz .container-wrapper--color &,
    .inbounz .container-wrapper--black & {
        color: color("white");
    }

    .container-wrapper--center & {
        text-align: center;
    }

    +.container {
        @media #{map-get($mediaqueries, "small-down")} {
            padding-bottom: 50px;
        }
    }
}

.container-badge {
    position: absolute;
    bottom: 50px;
    right: 50px;
    z-index: 5;
    width: 220px;
    max-width: calc(100% - 100px);
    border: 0;

    @media #{map-get($mediaqueries, "medium-down")} {
        bottom: 25px;
        right: 25px;
    }
}

.container-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -25px;

    >* {
        padding: 25px;
        width: 100%;
        max-width: 300px;
        text-align: center;
    }
}

.parallax {
    // overflow: hidden;
}