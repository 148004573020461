.button {
	position: relative;
	display: inline-block;
	padding: 10px 35px;
	font-size: 0.875rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
	border: 0;
	color: color("black");
	transition: .2s .1s ease-out;
	margin: 1.5rem 0;
	overflow: hidden;
	vertical-align: middle;
	.inbounz & {
		color: color("black");
	}
	.outbounz & {
		color: color("corporate");
	}
	&:before, &:after {
		content: "";
		position: absolute;
		width: 40px;
		height: 100%;
		border-radius: 20px;
		z-index: -1;
		transition: .2s ease-out;
		.inbounz & {
			background: color("corporate");
		}
		.outbounz & {
			background: color("black");
		}
	}
	&:after {
		top: 0;
		right: 0;
		transform: translateY(-100%);
		transition: .1s ease-out;
	}
	&:before {
		bottom: 0;
		left: 0;
		width: 100%;
		transition: .2s .1s ease-out;
	}
	&:hover {
		padding: 10px 60px 10px 10px;
		transition: .2s ease-out;
		&:after {
			transform: translateY(0);
			transition: .1s .1s ease-in;
		}
		&:before {
			width: calc(100% - 50px);
			transition: .2s ease-out;
		}
	}
	.inbounz &.button--color {
		color: color("corporate");
		&:before, &:after {
			background: color("black");
		}
	}
	.outbounz &.button--color {
		color: color("black");
		&:before, &:after {
			background: color("corporate");
		}
	}
	&.button--highlight {
		box-shadow: 0 0 6px rgba(0,0,0,.35);
		&:hover {
			box-shadow: none;
		}
	}
	&.button--disabled {
		opacity: .5;
		pointer-events: none;
		cursor: default;
	}
}
