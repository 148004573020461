// SETTINGS
@import "./node_modules/normalize.css/normalize";
@import "./node_modules/fullpage.js/dist/fullpage";
@import "./node_modules/slick-carousel/slick/slick";
@import "./tools/mixins";
@import "./settings/settings";


// ELEMENTS
// app
@import "./atoms/page";
@import "./atoms/headings";
@import "./atoms/animations";


// OBJECTS
@import "./molecules/buttons";
@import "./molecules/nav";
@import "./molecules/doc";
@import "./molecules/footer";
@import "./molecules/blog";
@import "./molecules/slick";
@import "./molecules/siteswapper";

// UTILITIES
@import "./tools/utilities";