.footer-wrapper {
	position: relative;
	width: 100%;
	max-width: $containerWidth;
	padding: 50px;
	margin: 0 auto;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	@media #{map-get($mediaqueries, "medium-down")} {
		padding: 25px;
	}
	@media #{map-get($mediaqueries, "small-down")} {
		flex-direction: column;
		text-align: center;
	}
	> div {
		width: calc(50% - 25px);
		@media #{map-get($mediaqueries, "small-down")} {
			width: 100%;
			margin-top: 25px;
		}
	}
	p:last-child {
		margin-bottom: 0;
	}
}