h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.875rem;
    margin-top: 0.875rem;
}

h1,
.h1 {
    margin: 0 0 .75rem;
    font-size: 2.375rem;
    font-weight: bold;
    line-height: 1.2;

    @media #{map-get($mediaqueries, "medium-up")} {
        font-size: calc(2.375rem + 0.025 * (100vw - 1020px));
    }

    @media #{map-get($mediaqueries, "xlarge-up")} {
        font-size: 3.75rem;
    }

    @media #{map-get($mediaqueries, "small-down")} {
        hyphens: auto;
    }
}

h2,
.h2 {
    margin: 0 0 .75rem;
    font-size: 1.875rem;
    font-weight: bold;
    line-height: 1.2;

    @media #{map-get($mediaqueries, "medium-up")} {
        font-size: calc(1.875rem + 0.015 * (100vw - 1020px));
    }

    @media #{map-get($mediaqueries, "xlarge-up")} {
        font-size: 2.75rem
    }

    @media #{map-get($mediaqueries, "small-down")} {
        hyphens: auto;
    }
}

h3,
.h3 {
    margin: 0 0 .75rem;
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 1.2;

    @media #{map-get($mediaqueries, "medium-up")} {
        font-size: calc(1.75rem + 0.01 * (100vw - 1020px));
    }

    @media #{map-get($mediaqueries, "xlarge-up")} {
        font-size: 2.125rem
    }

    @media #{map-get($mediaqueries, "small-down")} {
        hyphens: auto;
    }
}

h4,
.h4 {
    margin: 0;
    font-size: 0.875rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 0 0 #000;
}

h5,
.h5 {
    margin: 0 0 .75rem;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.2;
}

.subtitle {
    font-size: 0.875rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.intro {
    font-size: 1.125rem;
    margin: 0 0 .75rem;

    @media #{map-get($mediaqueries, "medium-up")} {
        font-size: calc(1.125rem + 0.0025 * (100vw - 1020px));
    }

    @media #{map-get($mediaqueries, "xlarge-up")} {
        font-size: 1.25rem
    }

    @media #{map-get($mediaqueries, "small-down")} {
        hyphens: auto;
    }
}

.intro-link {
    font-size: 1rem;
    white-space: nowrap;

    i {
        margin: 0 15px;

        @media #{map-get($mediaqueries, "small-down")} {
            margin-left: 0;
        }
    }

    @media #{map-get($mediaqueries, "small-down")} {
        display: block;
    }
}