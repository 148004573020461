$arrow-width: 4px;
.slick-slide {
    @media #{media("medium-down")} {
		padding-left: 25px;
		padding-right: 25px;
	}
}
[class^="slick-arrow"] {
	position: absolute;
	top: 0;
	width: 50px;
	height: 100%;
	border-radius: calc($arrow-width / 2);
	cursor: pointer;
	transition: transform .2s ease-in-out;
	z-index: 2;
	&:before {
		content: "";
		position: absolute;
		width: $arrow-width * 6;
		height: $arrow-width;
		top: calc(50% - #{calc($arrow-width / 2)});
		background: color("black");
		border-radius: calc($arrow-width / 2);
		.outbounz .container-wrapper--color &,
		.inbounz .container-wrapper--black & {
			background: color("white");
		}
	}
	&:after {
		content: "";
		position: absolute;
		width: $arrow-width;
		height: $arrow-width;
		top: calc(50% - #{calc($arrow-width / 2)});
		background: color("corporate");
		border-radius: calc($arrow-width / 2);
		.outbounz .container-wrapper--black &,
		.inbounz .container-wrapper--color & {
			background: color("white");
		}
	}
	span {
		position: absolute;
		top: 50%;
		&:after, &:before {
			content: "";
			position: absolute;
			width: $arrow-width * 3;
			height: $arrow-width;
			top: calc($arrow-width * -1 / 2);
			background: color("black");
			border-radius: calc($arrow-width / 2);
			transition: transform .2s ease-in-out;
			.outbounz .container-wrapper--color &,
			.inbounz .container-wrapper--black & {
				background: color("white");
			}
		}
		&:after {
			transform: rotate(-40deg);
		}
		&:before {
			transform: rotate(40deg);
		}
	}
	&:hover {
		width: 60px;
		span:after {
			transform: rotate(40deg);
		}
		span:before {
			transform: rotate(-40deg);
		}

	}
}
.slick-arrow-left {
	left: 50px;
	&:hover {
		transform: translateX(-10px);
	}
    @media #{media("medium-down")} {
        left: 15px;
    }
	&:before {
		left: $arrow-width * 2;
	}
	&:after {
		left: 0;
	}
	span {
		left: 0;
		&:after, &:before {
			left: $arrow-width * 2;
			transform-origin: #{$arrow-width * -2} center;
		}
	}
}
.slick-arrow-right {
	right: 50px;
	&:hover {
		transform: translateX(10px);
	}
    @media #{media("medium-down")} {
        right: 15px;
    }
	&:before {
		right: $arrow-width * 2;
	}
	&:after {
		right: 0;
	}
	span {
		right: 0;
		&:after, &:before {
			right: $arrow-width * 2;
			transform-origin: #{$arrow-width * 5} center;
		}
	}
}